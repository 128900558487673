import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";

export default function Services() {
  return (
    <>
      <Header />
      <main className="main-area fix">
        <section className="breadcrumb-area">
          <div
            className="breadcrumb-bg"
            data-background="assets/img/bg/breadcrumb_bg.png"
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="breadcrumb-content text-center">
                  <h3 className="title">Our Services</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Our Services
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="services-area inner-services-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="services-items-wrapper">
                  <ul className="list-wrap">
                    <li>
                      <div className="top-content">
                        <i className="flaticon-spyware"></i>
                        <span>Tracking System</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-cloud-computing"></i>
                        <span>Ownership Proof</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-hosting"></i>
                        <span>Data Backup</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-credit-card"></i>
                        <span>Remote Control</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      {/* <div className="top-content">
                        <i className="flaticon-concept"></i>
                        <span>Data Security</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-brain"></i>
                        <span>A.I. Threat Learning</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-hacker"></i>
                        <span>Client Protection</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-backup"></i>
                        <span>Scheduled Backups</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-connection"></i>
                        <span>Network Scanning</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-data-protection"></i>
                        <span>CPU Threats Safety</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-seo-and-web"></i>
                        <span>Disaster Recovery</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-face-recognition"></i>
                        <span>Face Recognition</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-map"></i>
                        <span>Location Tracking</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-coding"></i>
                        <span>Terminal Protection</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                    <li>
                      <div className="top-content">
                        <i className="flaticon-approved"></i>
                        <span>Logistic Security</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </li>
                  </ul> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
