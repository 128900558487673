import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";

export default function ContactUs() {
  return (
    <>
      <Header />
      <main className="main-area fix">
        <section className="breadcrumb-area">
          <div
            className="breadcrumb-bg"
            data-background="assets/img/bg/breadcrumb_bg.png"
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="breadcrumb-content text-center">
                  <h3 className="title">Contact Us</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact Us
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-area">
          <div className="contact-info-wrapper">
            <div className="container">
              <div className="row justify-content-around">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="contact-info-item text-center">
                    <div className="contact-info-icon">
                      <i className="flaticon-chat"></i>
                    </div>
                    <div className="contact-info-content">
                      <h5 className="title">Chat With Us</h5>
                      <p>
                        We've got live Social Experts waiting to help you monday
                        to friday from 9am to 5pm EST.
                      </p>
                      <a href="#" className="contact-info-link">
                        Chat with us
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="contact-info-item text-center">
                    <div className="contact-info-icon">
                      <i className="flaticon-open-mail"></i>
                    </div>
                    <div className="contact-info-content">
                      <h5 className="title">Send Us Email</h5>
                      <p>
                        Simple drop us an email at support@mypruber.com and
                        you'll receive a reply within 24 hours
                      </p>
                      <a
                        href="mailto:support@mypruber.com"
                        className="contact-info-link"
                      >
                        Email Us
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div className="contact-info-item text-center">
                    <div className="contact-info-icon">
                      <i className="flaticon-open-mail"></i>
                    </div>
                    <div className="contact-info-content">
                      <h5 className="title">Make a Call</h5>
                      <p>
                        Give us a ring.Our Experts are standing by monday to
                        friday from 9am to 5pm EST.
                      </p>
                      <a href="tel:123456789" className="contact-info-link">
                        +234 9043205555
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map" data-background="assets/img/bg/map.jpg"></div>
          <div className="contact-form-wrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-7 col-md-9 col-sm-10">
                  <div className="section-title text-center mb-50">
                    <h2 className="title">
                      Questions or Comments? Get in Touch
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xxl-8 col-xl-9 col-lg-10">
                  <form action="#" className="contact-form text-center">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input
                            type="text"
                            required
                            placeholder="Your full name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input
                            type="email"
                            required
                            placeholder="Your email address"
                          />
                        </div>
                      </div>
                      <div className="form-grp">
                        <textarea
                          name="message"
                          id="message"
                          required
                          placeholder="Write your message..."
                        ></textarea>
                      </div>
                    </div>
                    <button type="submit" className="btn">
                      Send message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
