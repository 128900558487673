import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <main className="main-area fix">
        <section className="breadcrumb-area">
          <div
            className="breadcrumb-bg"
            data-background="assets/img/bg/breadcrumb_bg.png"
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="breadcrumb-content text-center">
                  <h3 className="title">Privacy Policy</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Privacy Policy
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="privacy-area">
          Sorry, this is not ready yet. We are re-building it with our legal
          team
        </section>
      </main>
      <Footer />
    </>
  );
}
