import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import Team from "../components/team";

export default function About() {
  return (
    <>
      <Header />
      <main className="main-area fix">
        <section className="breadcrumb-area">
          <div
            className="breadcrumb-bg"
            data-background="assets/img/bg/breadcrumb_bg.png"
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="breadcrumb-content text-center">
                  <h3 className="title">About Us</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-area inner-about-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img">
                  <img src="assets/img/others/about.png" alt="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-11">
                <div className="about-content">
                  <h2 className="title">Pruber</h2>
                  <p>
                    The first growing centralized property registration and
                    verification system in Nigeria was built to enable people to
                    protect their owned possessions. Using Pruber, all
                    properties must be verified before any transaction can take
                    place on them. This means that stolen items cannot be sold,
                    as no one can assume ownership of them.​ With Pruber, owners
                    of property can easily track down their missing or stolen
                    possessions.​
                  </p>

                  <h3>How does Pruber work?</h3>
                  <ul>
                    <li>
                      People will register their products such as mobile phones,
                      computers, cars, TVs,Land, Houses and more by providing
                      proof of ownership.​
                    </li>
                    <li>
                      They can transfer the ownership of items such as mobile
                      phones, computers, cars, TVs, and more to another person
                      by providing proof of ownership, thus making the person
                      the new owner.​ ​
                    </li>
                    <li>
                      If a property goes missing, the owner can blacklist it on
                      Pruber so that no one can buy or sell it. Buyers of such
                      properties must verify it on Pruber before they make any
                      purchase to ensure that it is a genuine item and the
                      seller is the true owner.​
                    </li>
                    <li>
                      If anyone purchases a property from the original owner,
                      the ownership of the resource has to be transferred to the
                      buyer on Pruber so that the new owner is recorded on
                      Pruber.​
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fact-area inner-fact-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="fact-item">
                  <h2 className="count">
                    <span className="formatting-mark">+</span>
                    <span className="odometer" data-count="2164"></span>
                  </h2>
                  <span className="content">
                    Nationwide <br /> Users
                  </span>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="fact-item">
                  <h2 className="count">
                    <span className="formatting-mark">+</span>
                    <span className="odometer" data-count="120"></span>
                  </h2>
                  <span className="content">
                    Cyber Security <br /> Experts
                  </span>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="fact-item">
                  <h2 className="count">
                    <span className="odometer" data-count="95"></span>
                    <span className="formatting-mark">%</span>
                  </h2>
                  <span className="content">
                    Retention <br /> rate
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Team />

        <div className="marquee-area">
          <div className="marquee-wrap">
            <span>We protect data</span>
            <span>We prove real owner</span>
            <span>We track misisng devices</span>
            <span>We blacklist missing properties </span>
          </div>
        </div>
        {/* 
        <section className="testimonial-area testimonial-two-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div className="testimonial-active">
                  <div className="testimonial-item text-center">
                    <div className="testi-quote">
                      <img src="assets/img/icons/quote.png" alt="icon" />
                    </div>
                    <p>
                      “I have been using Pruber for my authentic ownership
                      properties verification and device tracking needs for the
                      past few months and I am very pleased with the results.”
                    </p>
                    <div className="testimonial-avatar">
                      <div className="testi-avatar-img">
                        <img src="assets/img/others/avatar01.png" alt="img" />
                      </div>
                      <div className="testi-avatar-info">
                        <h5 className="name">Peterson / Ceo & Founder</h5>
                        <div className="testi-rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item text-center">
                    <div className="testi-quote">
                      <img src="assets/img/icons/quote.png" alt="icon" />
                    </div>
                    <p>
                      “The security provided by Pruber is top-notch and I have
                      never had any issues with the verification process. The
                      properties verification and device tracking have been
                      extremely reliable and I can trust that my data is safe
                      and secure.”
                    </p>
                    <div className="testimonial-avatar">
                      <div className="testi-avatar-img">
                        <img src="assets/img/others/avatar02.png" alt="img" />
                      </div>
                      <div className="testi-avatar-info">
                        <h5 className="name">Rensona / Ceo & Founder</h5>
                        <div className="testi-rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item text-center">
                    <div className="testi-quote">
                      <img src="assets/img/icons/quote.png" alt="icon" />
                    </div>
                    <p>
                      “Using Pruber for property verification has been a
                      game-changer for our real estate business. The system's
                      efficiency in ensuring accurate property details has saved
                      us both time and money.”
                    </p>
                    <div className="testimonial-avatar">
                      <div className="testi-avatar-img">
                        <img src="assets/img/others/avatar03.png" alt="img" />
                      </div>
                      <div className="testi-avatar-info">
                        <h5 className="name">Markline / Ceo & Founder</h5>
                        <div className="testi-rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}
