import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";

export default function PageNotFound() {
  return (
    <>
      <Header />
      <main className="main-area fix">
        <section className="error-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-7 col-lg-9 col-md-10">
                <div className="error-content text-center">
                  <h2 className="error-text">404</h2>
                  <h5 className="content">
                    Sorry, the page you are looking for could not be found
                  </h5>
                  <Link to="/" className="btn back-btn">
                    <span className="text">Back to home</span>
                    <span className="shape"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
