export default function Team() {
  return (
    <section className="team-area team-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className="section-title text-center mb-55">
              <h2 className="title">
                Expert Team Who Are Behind the Whole System
              </h2>
            </div>
          </div>
        </div>
        <div className="team-wrapper">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-item">
                <div className="team-thumb">
                  <img src="assets/img/team/rabiu.png" alt="img" />
                </div>
                <div className="team-content">
                  <h4 className="name">Rabiu Aliyu</h4>
                  <span className="designation">Lead Software Engineer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-item">
                <div className="team-thumb">
                  <img src="assets/img/team/khan.png" alt="img" />
                </div>
                <div className="team-content">
                  <h4 className="name">Umar Nuhu Abba</h4>
                  <span className="designation">
                    Senior Blockchain Engineer
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-item">
                <div className="team-thumb">
                  <img src="assets/img/team/abba.png" alt="img" />
                </div>
                <div className="team-content">
                  <h4 className="name">Abddullahi Nasir</h4>
                  <span className="designation">
                    Fullstack Software Engineer
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="team-item">
                <div className="team-thumb">
                  <img src="assets/img/team/canada.png" alt="img" />
                </div>
                <div className="team-content">
                  <h4 className="name">Umar Ahmed</h4>
                  <span className="designation">Developer/Security Head</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
        <div className="col-12">
          <div className="team-btn text-center">
            <Link to="/team" className="btn btn-style-two">
              <span className="text">Join our team</span>
              <span className="shape"></span>
            </Link>
          </div>
        </div>
      </div> */}
      </div>
    </section>
  );
}
