import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
      className="footer-area footer-bg"
      data-background="assets/img/bg/footer_bg.jpg"
    >
      <div className="container">
        <div className="footer-top-wrap">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-widget">
                <div className="footer-contact-info">
                  <h4 className="number">+234 9043205555</h4>
                  <h4 className="email">support@mypruber.com</h4>
                  <p>
                    Suite 14C Syrol Technologies Plaza, Sharada Phase 1, Kano
                    City,
                    <br /> Nigeria
                  </p>
                  <ul className="footer-social list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-4 column-2">
              <div className="footer-widget">
                <h4 className="fw-title">Quick Links</h4>
                <ul className="footer-link">
                  <li>
                    <Link to="/about">About us</Link>
                  </li>

                  <li>
                    <Link to="/terms-condition">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/terms-condition">Data Protection Policy</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Poilicy</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-4 column-3">
              <div className="footer-widget">
                <h4 className="fw-title">Our Major Services</h4>
                <ul className="footer-link">
                  <li>Verification</li>
                  <li>Realtime Tracking</li>
                  <li>Remote Control</li>
                  <li>Data Backup</li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-6 col-sm-7">
              <div className="footer-widget">
                <form action="#" className="newsletter-form">
                  <div className="form-grp">
                    <input
                      type="email"
                      required
                      placeholder="Your email address"
                    />
                  </div>
                  <button type="submit" className="btn">
                    Subscribe now <span className="shape"></span>
                  </button>
                  <p className="newsletter-alert">
                    <span>*</span> Don't worry, we don't spam
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <p className="copyright-text">
            Copyright ©2023 Developed By{" "}
            <a href="https://syroltech.com">
              <span>Syrol Technologies Ltd</span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
