import Footer from "../components/footer";
import Header from "../components/header";
import Team from "../components/team";

export default function Index() {
  return (
    <>
      <Header />
      <main className="main-area fix">
        <div className="area-two-bg-wrap">
          <section className="banner-area banner-two-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="banner-two-img text-center text-lg-start">
                    <img src="assets/img/banner/banner_img02.png" alt="img" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="banner-content">
                    <h2 className="heading wow fadeInUp" data-wow-delay=".2s">
                      Protect <span>Your Properties </span> Against Thieves and
                      Hijackers
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Pruber is a blockchain-based centralized ownership
                      verification system built to enable you to register and
                      protect all your properties from being traded or illegal
                      use without your consent. Using Pruber, all properties
                      like <b>Land, Houses, Vehicles, Smart Devices</b> must be
                      verified before any transaction can take place on them.​
                    </p>

                    <div className="d-flex-inline gap-2">
                      <a
                        href="https://dashboard.mypruber.com"
                        className="btn btn-danger"
                      >
                        <span className="text">Get Started</span>
                        <span className="shape"></span>
                      </a>
                      &nbsp; &nbsp; &nbsp;
                      <a href="" className="btn btn-primary">
                        <span className="text">Chat With Us</span>
                        <span className="shape"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="fact-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="fact-item">
                    <h2 className="count">
                      <span className="formatting-mark">+</span>
                      <span className="odometer" data-count="2164"></span>
                    </h2>
                    <span className="content">
                      Nationwide <br /> users
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="fact-item">
                    <h2 className="count">
                      <span className="formatting-mark">+</span>
                      <span className="odometer" data-count="120"></span>
                    </h2>
                    <span className="content">
                      Cyber Security <br /> Experts
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="fact-item">
                    <h2 className="count">
                      <span className="odometer" data-count="95"></span>
                      <span className="formatting-mark">%</span>
                    </h2>
                    <span className="content">
                      Retention <br /> rate
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="services-two-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="section-title text-center mb-55">
                  <h2 className="title">Benafits of Pruber System</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xxl-10">
                <div className="services-two-items-wrap">
                  <div className="row g-0 justify-content-center">
                    <div className="col-md-6 col-sm-8">
                      <div className="services-two-item">
                        <div className="services-two-icon">
                          <i className="flaticon-broken-shield"></i>
                        </div>
                        <div className="services-two-content">
                          <h3 className="title">Ownership Proof</h3>
                          <p>
                            Your Pruber registered properties like Land, Houses,
                            Smart devices, and Vehicles are proven to the world
                            that it's yours so that snatchers/hijackers can't
                            trade them without your consent.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-8">
                      <div className="services-two-item">
                        <div className="services-two-icon">
                          <i className="flaticon-blackmail"></i>
                        </div>
                        <div className="services-two-content">
                          <h3 className="title">Tracking System</h3>
                          <p>
                            With Pruber you can be able to track the registered
                            resources in real-time as well as require its
                            current location/address, especially the smart
                            devices and vehicles
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-8">
                      <div className="services-two-item">
                        <div className="services-two-icon">
                          <i className="flaticon-cyber-security"></i>
                        </div>
                        <div className="services-two-content">
                          <h3 className="title">Remote Control</h3>
                          <p>
                            On the Pruber app, smart devices and vehicles can be
                            controlled remotely by switching off Phones,
                            recording voice and Video, cutting off fuel, turning
                            off the Car, raising an Alarm for Vehicles
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-8">
                      <div className="services-two-item">
                        <div className="services-two-icon">
                          <i className="flaticon-assignment"></i>
                        </div>
                        <div className="services-two-content">
                          <h3 className="title">Data Backup</h3>
                          <p>
                            Using Pruber, you can easily access and restore data
                            stored in a missing or stolen mobile phone or
                            computer by login in on a different device with your
                            Username and Password
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="services-explore-btn text-center">
                  <a
                    href="https://dashboard.mypruber.com/login"
                    className="btn"
                  >
                    <span className="text">Get in Now</span>
                    <span className="shape"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-area security-area">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-8">
                <div className="about-img">
                  <img
                    src="assets/img/others/security_img.png"
                    className="wow fadeInLeft"
                    data-wow-delay=".2s"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-8 col-md-11">
                <div className="about-content">
                  <h2 className="title">Why should you trust us?</h2>
                  <p>
                    Some of the services we use to ensure your data is secured
                  </p>
                  <ul className="about-list security-list">
                    <li>On-premise environment</li>
                    <li>End to end encryption</li>
                    <li>Two factor authentication</li>
                    <li>Internet IP access ristriction</li>
                    <li>Malware monitoring</li>
                    <li>Cyber security services</li>
                    <li>Database security services</li>
                    <li>Permision control services</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="marquee-area marquee-style-two">
          <div className="marquee-wrap">
            <span>Track your smart devices</span>
            <span>Track your vehicles</span>
            <span>Send instruction to your devices</span>
            <span>We prove ownership of your Land</span>
            <span>We prove ownership of your House</span>
            <span>We ensure your data is safe</span>
          </div>
        </div>

        <section className="steps-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="section-title text-center mb-55">
                  <h2 className="title">
                    Basic Onboarding Process on Pruber System.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="steps-item text-center wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="steps-img">
                    <img src="assets/img/others/steps_01.png" alt="img" />
                  </div>
                  <div className="steps-content">
                    <h4 className="title">Signup on Pruber</h4>
                    <p>
                      Provide your basic information and get your account setup
                      on the Pruber platform
                    </p>
                    <span className="steps-count">Step one</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="steps-item text-center wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <div className="steps-img">
                    <img src="assets/img/others/steps_02.png" alt="img" />
                  </div>
                  <div className="steps-content">
                    <h4 className="title">Login to Pruber</h4>
                    <p>
                      Login to Pruber with your Username and Password on your
                      smart device
                    </p>
                    <span className="steps-count">Step two</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="steps-item text-center wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="steps-img">
                    <img src="assets/img/others/steps_03.png" alt="img" />
                  </div>
                  <div className="steps-content">
                    <h4 className="title">Register resources</h4>
                    <p>
                      Register your resources by navigating to the Register
                      resource section on the app
                    </p>
                    <span className="steps-count">Step three</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="steps-item text-center wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="steps-img">
                    <img src="assets/img/others/steps_01.png" alt="img" />
                  </div>

                  <div className="steps-content mt-5">
                    <h4 className="title">Verify resource</h4>
                    <p>
                      Ensure you verify any other property you are buying before
                      you buy on the app.
                    </p>
                    <span className="steps-count">Step four</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="steps-item text-center wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="steps-img">
                    <img src="assets/img/others/steps_01.png" alt="img" />
                  </div>
                  <div className="steps-content mt-5">
                    <h4 className="title">Get ownership</h4>
                    <p>
                      When you buy Property next, get the seller to transfer the
                      ownership to you on Pruber.
                    </p>
                    <span className="steps-count">Step five</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="steps-item text-center wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="steps-img">
                    <img src="assets/img/others/steps_01.png" alt="img" />
                  </div>
                  <div className="steps-content mt-5">
                    <h4 className="title">Prove or Track</h4>
                    <p>
                      For missing or hijacked properties, you can use Pruber to
                      prove ownership or track it.
                    </p>
                    <span className="steps-count">Step six</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Team />
        <div className="video-area">
          <div className="video-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="video-play-icon">
                  <a
                    href="https://www.youtube.com/watch?v=J_t6OX1HRKg"
                    className="popup-video"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="brand-area brand-two-area">
          <div className="container">
            <div className="brand-wrap">
              <div className="row">
                <div className="col-12">
                  <h4 className="brand-title">
                    Trusted by more than <span>+750</span> companies around the
                    globe
                  </h4>
                </div>
              </div>
              <div className="row brand-active">
                <div className="col-2">
                  <div className="brand-item">
                    <a href="#">
                      <img src="./../assets/img/brand/brand_01.png" alt="img" />
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="brand-item">
                    <a href="#">
                      <img src="assets/img/brand/brand_02.png" alt="img" />
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="brand-item">
                    <a href="#">
                      <img src="assets/img/brand/brand_03.png" alt="img" />
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="brand-item">
                    <a href="#">
                      <img src="assets/img/brand/brand_04.png" alt="img" />
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="brand-item">
                    <a href="#">
                      <img src="assets/img/brand/brand_05.png" alt="img" />
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="brand-item">
                    <a href="#">
                      <img src="assets/img/brand/brand_02.png" alt="img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}
