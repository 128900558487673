import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <button className="scroll-top scroll-to-target" data-target="html">
        <i className="fas fa-angle-up"></i>
      </button>
      <header>
        <div
          id="sticky-header"
          className="menu-area transparent-header header-style-two"
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <Link to="/">
                        <img src="assets/img/logo/logo-white.png" alt="Logo" />
                      </Link>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className="active">
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        {/* <li>
                          <Link to="/services">Services</Link>
                        </li> */}
                        <li>
                          <Link to="/faqs">FAQs</Link>
                        </li>
                        <li>
                          <Link to="/contact">contacts</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="header-social">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="flaticon-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="flaticon-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="flaticon-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="offcanvas-btn">
                      <a href="#">
                        <img src="assets/img/icons/dots.png" alt="Icon" />
                      </a>
                    </div>
                  </nav>
                </div>

                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times"></i>
                    </div>
                    <div className="nav-logo">
                      <Link to="/">
                        <img src="assets/img/logo/logo.png" alt="" title="" />
                      </Link>
                    </div>
                    <div className="menu-outer"></div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="offCanvas-wrap">
          <div className="offCanvas-toggle">
            <img src="assets/img/icons/cross.svg" alt="icon" />
          </div>
          <div className="offCanvas-body">
            <div className="offCanvas-content">
              <h3 className="title">
                Digital safety <span>starts here</span> for both commercial and
                personal
              </h3>
              <p>
                Nam libero tempore, cum soluta nobis eligendi cumque quod
                placeat facere possimus assumenda omnis dolor repellendu sautem
                temporibus officiis
              </p>
            </div>
            <div className="offcanvas-contact footer-contact-info">
              <h4 className="number">+234 9043205555</h4>
              <h4 className="email">support@mypruber.com</h4>
              <p>
                3828 Delmas Terrace, Culver City, <br /> CA, United States
              </p>
              <ul className="footer-social list-wrap">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="offCanvas-overlay"></div>
      </header>
    </>
  );
}
