import { Route, Routes } from "react-router-dom";
import Index from "./pages";
import PageNotFound from "./pages/404";
import About from "./pages/about";
import ContactUs from "./pages/contactus";
import DataProtectionPolicy from "./pages/data-protection-policy";
import Faqs from "./pages/faqs";
import PrivacyPolicy from "./pages/privacy-policy";
import Services from "./pages/services";
import TermsAndConditions from "./pages/terms-condition";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<Index />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/contact" element={<ContactUs />} />
      <Route exact path="/faqs" element={<Faqs />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-condition" element={<TermsAndConditions />} />
      <Route
        exact
        path="/data-protection-policy"
        element={<DataProtectionPolicy />}
      />
      <Route exact path="/*" element={<PageNotFound />} />
    </Routes>
  );
}
